import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/mitgliederversammlung/2021/01.jpg"
import Image02 from "../../../images/mitgliederversammlung/2021/02.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Mitgliederversammlung2021 = props => {
  return (
    <Layout>
      <SEO title="Mitgliederversammlung 2021" lang="de" />
      <Container>
        <h1 className="h3">
          Mitgliederversammlung des Schützenverein 1961 Gambach e.V.
        </h1>
        <hr className="featurette-divider" />
        <small>Aus der Butzbacher Zeitung</small>
        <h6>Schützenverein Gambach zieht Bilanz</h6>
        <p>
          Gambach (fs). Am vergangenen Samstag hatte der Schützenverein 1961
          Gambach e.V. seine Mitglieder zur diesjährigen Ordentlichen
          Mitgliederversammlung und zum anschließenden Sommerfest eingeladen.
          Bei strahlendem Sonnenschein waren sehr viele Schießsportbegeisterte
          der Einladung gefolgt. Die Mitgliederversammlung des Schützenvereins
          stand ganz im Zeichen eines durch Corona gezeichneten Vereinsjahres
          2020. Lediglich die zu Beginn des Jahres abgehaltene
          Mitgliederversammlung im Februar konnte noch ohne Einschränkungen
          abgehalten werden. Danach folgte der erste Lockdown und die
          Einschränkungen in allen Bereichen.
        </p>
        <p>
          Nach dem Gedenken an die verstorbenen Mitglieder ging der Vorsitzende
          Friedhelm Schwenz in seinem Bericht auf die Situation des vergangenen
          und des laufenden Jahres ein. Leider mussten ab März 2020 alle
          geplanten gesellschaftlichen und kulturellen Veranstaltungen abgesagt
          werden. Der Trainingsbetrieb kam fast vollständig zum Erliegen. Die
          Meisterschaften und Rundenwettkämpfe wurden verschoben. Zum Jahresende
          wurden diese sowie die Rundenwettkämpfe als „Fernwettkämpfe“
          durchgeführt, d.h. jede Mannschaft schoss auf ihrem eigenen Stand
          unter den entsprechenden Hygienebestimmungen. Die gemeinnützige Arbeit
          und aktive Jugendarbeit wurden durch die Volksbank Butzbach und die
          Stadt Münzenberg im Rahmen der städtischen Vereinsförderung
          unterstützt. In seinem Sportbericht erläuterte Harald Fink die guten
          Leistungen und erfolgreichen Platzierungen der vergangenen Saison.
          Hier waren gute Leistungen der drei Luftgewehr-Mannschaften und
          Einzelschützen bei den verschiedenen Meisterschaften zu verzeichnen.
          Im Anschluss wurden die erfolgreichen Schützen des Gambacher Vereins
          bei den diesjährigen Kreis-, Gau- und Landesmeisterschaften geehrt.
          Hier waren wieder sehr gute Einzel- und auch Mannschaftsplatzierungen
          erzielt worden. Die Erstplatzierten erhielten die entsprechenden
          Urkunden und Ehrennadeln. Die Mitgliederstatistik des Vereins war
          trotz der Pandemie stabil geblieben. Es wurden vier neue Mitglieder im
          Schützenverein begrüßt. Uwe Wagner wurde für seine 50jährige
          Vereinszugehörigkeit mit einer Urkunde und der entsprechenden
          Ehrennadel des Hessischen Schützenverbandes geehrt.
        </p>
        <p>
          In seiner Übersicht stellte Kassenwart Lothar Döring die geordneten
          Finanzen und die solide Situation des Vereins dar. Eine vorbildliche
          Führung der Kassen- und Buchführungsgeschäfte wurde von den
          Kassenprüfern Andreas Bosse und Andreas Krahl attestiert, verbunden
          mit dem Antrag auf Entlastung des Gesamtvorstandes, welchem die
          Versammlung einstimmig folgte. Unter Punkt Verschiedenes wurden noch
          Themen bezüglich des Vereinsjahres behandelt und die geplanten
          Veranstaltungen für das laufende sowie das kommende Jahr besprochen.
        </p>
        <p>
          Im Anschluss an die Ordentliche Mitgliederversammlung ließen sich die
          Anwesenden die vielen selbstgebackenen Kuchen zum Kaffee schmecken.
          Nach einer kurzen Pause startete dann das traditionelle
          Königsschießen. Hierbei wurde mit dem Luftgewehr (10 m) getrennt nach
          Damen und Herren auf die von Michael Rheinfrank angefertigten
          hölzernen Adler geschossen. Hierbei galt es in bestimmter Reihenfolge,
          zuerst das „Zepter“, dann den „Apfel“ und zuletzt die „Krone“ des
          Adlers abzuschießen. Den Erfolgreichen gebührt dann der Titel des „2.
          Edelfräulein“ bzw. „2. Ritter“, des „1. Edelfräulein“ bzw. „1. Ritter“
          sowie letztendlich der Titel der „Königin“ bzw. des „Königs“. Nach
          über zweistündigem Wettkampf errang diesmal Sabine Döring den Titel
          der Schützenkönigin und Friedhelm Schwenz den Titel des Königs. Die
          weiteren Ehrenbezeichnungen erhielten Rudolf Haas (2. Ritter), Gerhard
          Seiffermann (1. Ritter), Katharina Beppler-Alt (1. Edelfräulein) und
          Gerhild Schäfer (2. Edelfräulein). Gegen 19 Uhr fand dann die
          „Proklamation“ der neuen Gambacher Königsfamilie statt. Mit Leckereien
          vom Holzkohlegrill und selbstgemachten Salaten sowie gekühlten
          Getränken klang das Sommerfest in gemütlicher Runde erst in den späten
          Abendstunden aus.
        </p>

        <Row className="justify-content-center">
          <Col xs={6}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              GAMBACH. Erfolgreiche Aktive bei den diesjährigen Kreis-, Gau- und
              Landesmeisterschaften des Gambacher Schützenvereins, v.l.: Michael
              Rheinfrank, Fabian Zeh, Katharina Beppler-Alt, Willi Beppler-Alt,
              Heiko Lippert und Harald Fink
            </small>
          </Col>
          <Col xs={6}>
            <Image src={Image02} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              GAMBACH. Die Gambacher Königsfamilie 2021: 2. Ritter Rudolf Haas,
              1. Ritter Gerhard Seiffermann, Schützenkönig Friedhelm Schwenz,
              Schützenkönigin Sabine Döring 1. Edelfräulein Katharina
              Beppler-Alt, und 2. Edelfräulein Gerhild Schäfer
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Mitgliederversammlung2021
